import { Transport } from '_common/services/Realtime/Transport';
import { ModelIndexer } from '../Models/ModelIndexer';
import { StructureData, Suggestion, NodeModel, Structure } from '../../models';
import { Doc } from 'sharedb';

type SuggestionLocationsType = { [index: string]: { level0: string; elementId: string }[] };
export class SuggestionList extends ModelIndexer<'NODE'> {
  protected suggestionIndex: string[] = [];
  protected documentId?: string;
  protected structure?: Structure;
  private suggestions: { [index: string]: Suggestion } = {};
  private suggestionLocations: SuggestionLocationsType = {};
  protected timer?: any;
  protected loaded: boolean = false;

  constructor(transport: Transport, models: Editor.Data.Models.Controller) {
    super(transport, models, 'NODE');
    this.suggestionIndex = [];

    this.handleStructureLoaded = this.handleStructureLoaded.bind(this);
    this.handleStructureUpdated = this.handleStructureUpdated.bind(this);
    this.handleNodeSuggestionChanged = this.handleNodeSuggestionChanged.bind(this);
  }

  get list() {
    return this.version?.index || this.suggestionIndex;
  }

  get locations() {
    return this.version?.locations || this.suggestionLocations;
  }

  get data() {
    return this.suggestions;
  }

  getSuggestionById(suggestionId: string) {
    return this.data[suggestionId];
  }

  start(documentId: string) {
    this.documentId = documentId;
    this.qs = {
      parent_id: documentId,
      _id: { $in: this.structure?.childNodes },
      $and: [
        {
          refs: { $exists: true },
        },
        {
          'refs.tracked': { $not: { $size: 0 }, $exists: true },
        },
      ],
    };
    this.structure = this.models.get(this.models.TYPE_NAME.STRUCTURE, `DS${documentId}`);
    this.structure.on('LOADED', this.handleStructureLoaded);
    this.structure.on('CHILDREN_UPDATE', this.handleStructureUpdated);
    if (this.structure.loaded) {
      super.start(this.qs);
    }
  }

  private handleStructureLoaded(data: StructureData | null) {
    this.qs = {
      parent_id: this.documentId,
      _id: { $in: this.structure?.childNodes },
      $and: [
        {
          refs: { $exists: true },
        },
        {
          'refs.tracked': { $not: { $size: 0 }, $exists: true },
        },
      ],
    };
    super.start(this.qs);
  }

  private handleStructureUpdated() {
    this.qs = {
      parent_id: this.documentId,
      _id: { $in: this.structure?.childNodes },
      $and: [
        {
          refs: { $exists: true },
        },
        {
          'refs.tracked': { $not: { $size: 0 }, $exists: true },
        },
      ],
    };
    super.start(this.qs);
  }

  handleQueryReady() {
    super.handleQueryReady();
    this.reIndex();
  }

  handleQueryInsertedElements(docs: Doc[], index: number) {}

  handleQueryRemovedElements(docs: Doc[], index: number) {}

  handleQueryElementsChanged(docs: Doc[]) {
    const nodesDiff = SuggestionList.diffInOut(
      this.results.map((n) => n.id),
      docs.map((n) => n.id),
    );
    if (nodesDiff.in.length > 0 || nodesDiff.out.length > 0) {
      for (let index = 0; index < nodesDiff.in.length; index++) {
        const newNode: NodeModel = this.models.get(this.typeName, nodesDiff.in[index]);
        newNode.on('LOADED', this.handleNodeSuggestionChanged);
        newNode.on('SUGGESTIONS_CHANGED', this.handleNodeSuggestionChanged);
      }
      for (let index = 0; index < nodesDiff.out.length; index++) {
        const oldNode: NodeModel = this.models.get(this.typeName, nodesDiff.out[index]);
        oldNode.off('LOADED', this.handleNodeSuggestionChanged);
        oldNode.off('SUGGESTIONS_CHANGED', this.handleNodeSuggestionChanged);
      }
      this.results = this.q?.results.map((n) => this.models.get(this.typeName, n)) || [];
    }
    !this.version && this.emit('CHANGED', this.results);
    this.reIndex();
  }

  handleNodeSuggestionChanged() {
    this.reIndex();
  }

  reIndex() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.timer = null;
      let tempIndex: Set<string> = new Set();
      let node: NodeModel;

      this.orderResults();

      for (let index = 0, length = this.results.length; index < length; index++) {
        node = this.results[index];
        const suggRefs = node.getSuggestionRefs();
        for (let jIndex = 0; jIndex < suggRefs.length; jIndex++) {
          const suggestionRef = suggRefs[jIndex];
          if (suggestionRef.sug && suggestionRef.els) {
            tempIndex.add(suggestionRef.sug);
            if (!this.suggestionLocations[suggestionRef.sug]) {
              this.suggestionLocations[suggestionRef.sug] = [];
            }
            for (let index = 0; index < suggestionRef.els.length; index++) {
              const element = suggestionRef.els[index];
              this.suggestionLocations[suggestionRef.sug].push({
                level0: node.id,
                elementId: element,
              });
            }
          }
        }
      }

      let newIndex = Array.from(tempIndex);
      const suggestionsDiff = SuggestionList.diffInOut(this.suggestionIndex, newIndex);
      this.suggestionIndex = newIndex;
      if (
        suggestionsDiff.in.length > 0 ||
        suggestionsDiff.out.length > 0 ||
        suggestionsDiff.changedOrder ||
        !this.loaded
      ) {
        this.loaded = true;
        for (let index = 0; index < suggestionsDiff.in.length; index++) {
          this.suggestions[suggestionsDiff.in[index]] = this.models.get(
            'SUGGESTION',
            suggestionsDiff.in[index],
          );
        }
        for (let index = 0; index < suggestionsDiff.out.length; index++) {
          this.suggestions[suggestionsDiff.out[index]].destroy();
          delete this.suggestions[suggestionsDiff.out[index]];
        }
        !this.version && this.emit('CHANGED_DELTA', suggestionsDiff);
      }
    }, 250);
  }

  orderResults() {
    let orderedResults: NodeModel[] = [];
    let node: NodeModel;

    for (let index = 0, length = this.results.length; index < length; index++) {
      node = this.results[index];

      const sIndex = this.structure?.childNodes?.indexOf(node.id);
      if (sIndex !== undefined) {
        orderedResults[sIndex] = node;
      }
    }

    this.results = orderedResults.filter((item) => item !== undefined);
  }

  suggestionLocation(suggId: string) {
    return this.suggestionLocations[suggId] || [];
  }

  setVersionData(
    version: any,
    data?: {
      index: string[];
      locations: {};
    },
  ) {
    let index: string[];
    let oldIndex = this.version?.index || this.suggestionIndex;
    if (version) {
      this.version = {
        version,
        index: data?.index,
        locations: data?.locations,
      };
      index = this.version.index;
    } else {
      this.version = null;
      index = this.suggestionIndex;
    }
    const suggestionDiff = SuggestionList.diffInOut(oldIndex, index);
    if (suggestionDiff.in.length > 0 || suggestionDiff.out.length > 0) {
      for (let index = 0; index < suggestionDiff.in.length; index++) {
        this.suggestions[suggestionDiff.in[index]] = this.models.get(
          'SUGGESTION',
          suggestionDiff.in[index],
        );
      }
      for (let index = 0; index < suggestionDiff.out.length; index++) {
        this.suggestions[suggestionDiff.out[index]].destroy();
        delete this.suggestions[suggestionDiff.out[index]];
      }
      this.emit('CHANGED_DELTA', suggestionDiff);
    }
  }

  destroy() {
    super.destroy();
  }
}
