import { Doc } from 'sharedb';
import { IndexerDeltaType, ModelIndexer } from '../Models/ModelIndexer';
import { Transport } from '_common/services/Realtime/Transport';
import { Comment, NodeModel, Structure, StructureData } from '../../models';

type CommentRefType = {
  ref: string;
  els: string[];
};

type CommentLocationsType = { [index: string]: { level0: string; elementId: string }[] };

export class CommentList extends ModelIndexer<'NODE'> {
  protected commentIndex: string[] = [];
  protected tempCommentIndex: string[] = [];
  protected structure?: Structure;
  protected documentId?: string;
  protected author?: string;
  protected timer?: any;
  private commentLocations: CommentLocationsType = {};
  private tempCommentLocations: CommentLocationsType = {};
  private comments: { [index: string]: Comment } = {};
  protected loaded: boolean = false;
  constructor(transport: Transport, models: Editor.Data.Models.Controller) {
    super(transport, models, 'NODE');
    this.commentIndex = [];
    this.handleStructureLoaded = this.handleStructureLoaded.bind(this);
    this.handleStructureUpdated = this.handleStructureUpdated.bind(this);
    this.handleNodeCommentsChanged = this.handleNodeCommentsChanged.bind(this);
  }

  get list() {
    return this.version?.index || this.commentIndex;
  }

  get locations() {
    return this.version?.locations || this.commentLocations;
  }

  get data() {
    return this.comments;
  }

  start(documentId: string) {
    this.documentId = documentId;
    //this.author = author;
    this.structure = this.models.get(this.models.TYPE_NAME.STRUCTURE, `DS${documentId}`);
    this.structure.on('LOADED', this.handleStructureLoaded);
    // this.structure.on('UPDATED', this.handleStructureUpdated);
    this.structure.on('CHILDREN_UPDATE', this.handleStructureUpdated);
    if (this.structure.loaded) {
      super.start({
        parent_id: this.documentId,
        _id: { $in: this.structure?.childNodes },
        $and: [
          {
            refs: { $exists: true },
          },
          {
            $or: [
              {
                'refs.comments': { $not: { $size: 0 }, $exists: true },
              },
              {
                'refs.tempComments': { $not: { $size: 0 }, $exists: true },
              },
            ],
          },
        ],
      });
    }
  }

  private handleStructureUpdated(/* data: StructureData | null */) {
    super.start({
      parent_id: this.documentId,
      _id: { $in: this.structure?.childNodes },
      $and: [
        {
          refs: { $exists: true },
        },
        {
          $or: [
            {
              'refs.comments': { $not: { $size: 0 }, $exists: true },
            },
            {
              'refs.tempComments': { $not: { $size: 0 }, $exists: true },
            },
          ],
        },
      ],
    });
  }

  private handleStructureLoaded(data: StructureData | null) {
    super.start({
      parent_id: this.documentId,
      _id: { $in: this.structure?.childNodes },
      $and: [
        {
          refs: { $exists: true },
        },
        {
          $or: [
            {
              'refs.comments': { $not: { $size: 0 }, $exists: true },
            },
            {
              'refs.tempComments': { $not: { $size: 0 }, $exists: true },
            },
          ],
        },
      ],
    });
  }

  handleQueryReady() {
    super.handleQueryReady();
    this.reIndex();
  }

  handleQueryInsertedElements(docs: Doc[], index: number) {
    let newDocs: NodeModel[] = docs.map((doc) => {
      return this.models.get(this.typeName, doc);
    });
    this.results.splice(index, 0, ...newDocs);
    for (let index = 0; index < newDocs.length; index++) {
      const newNode = newDocs[index];
      newNode.on('LOADED', this.handleNodeCommentsChanged);
      newNode.on('COMMENTS_CHANGED', this.handleNodeCommentsChanged);
    }
    this.reIndex();
    this.emit('INSERTED', newDocs);
  }

  handleQueryRemovedElements(docs: Doc[], index: number) {
    let oldDocs: NodeModel[] = this.results.splice(index, docs.length);
    for (let index = 0; index < oldDocs.length; index++) {
      const oldNode = oldDocs[index];
      oldNode.off('LOADED', this.handleNodeCommentsChanged);
      oldNode.off('COMMENTS_CHANGED', this.handleNodeCommentsChanged);
    }
    this.reIndex();
    this.emit('REMOVED', oldDocs);
  }

  handleQueryElementsChanged() {}

  handleNodeCommentsChanged() {
    this.reIndex();
  }

  protected reIndex() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      let tempIndex: Set<string> = new Set();
      let tempCommentIndex: Set<string> = new Set();
      const tempLocations: CommentLocationsType = {};
      const tempCommentsLocations: CommentLocationsType = {};
      let node: NodeModel;

      this.orderResults();

      for (let index = 0, length = this.results.length; index < length; index++) {
        node = this.results[index];
        const commentRefs = node.getCommentsRefs();
        for (let jIndex = 0; jIndex < commentRefs.length; jIndex++) {
          const commentRef: CommentRefType = commentRefs[jIndex];

          tempIndex.add(commentRef.ref);

          if (!tempLocations[commentRef.ref]) {
            tempLocations[commentRef.ref] = [];
          }
          for (let t = 0; t < commentRef.els.length; t++) {
            tempLocations[commentRef.ref].push({
              level0: node.id,
              elementId: commentRef.els[t],
            });
          }
        }
        const commentTempRefs = node.getTempCommentsRefs();

        for (let jIndex = 0; jIndex < commentTempRefs.length; jIndex++) {
          const commentTempRef = commentTempRefs[jIndex];
          if (this.author === commentTempRef.author) {
            tempCommentIndex.add(commentTempRef.ref);

            if (!tempCommentsLocations[commentTempRef.ref]) {
              tempCommentsLocations[commentTempRef.ref] = [];
            }
            for (let t = 0; t < commentTempRef.els.length; t++) {
              tempCommentsLocations[commentTempRef.ref].push({
                level0: node.id,
                elementId: commentTempRef.els[t],
              });
            }
          }
        }
      }
      let newIndex = Array.from(tempIndex);
      this.commentLocations = tempLocations;
      this.tempCommentLocations = tempCommentsLocations;
      const commentsDiff = CommentList.diffInOut(this.commentIndex, newIndex);
      this.commentIndex = newIndex;
      let newIndexTemp = Array.from(tempCommentIndex);
      const tempCommentsDiff = CommentList.diffInOut(this.tempCommentIndex, newIndexTemp);
      this.tempCommentIndex = newIndexTemp;
      let delta: IndexerDeltaType<{
        comments: any[];
        temp: any[];
      }> = {
        in: { comments: [], temp: [] },
        out: { comments: [], temp: [] },
        changedOrder: commentsDiff.changedOrder,
      };

      if (
        commentsDiff.in.length > 0 ||
        commentsDiff.out.length > 0 ||
        commentsDiff.changedOrder ||
        !this.loaded
      ) {
        this.loaded = true;
        for (let index = 0; index < commentsDiff.in.length; index++) {
          this.comments[commentsDiff.in[index]] = this.models.get(
            'COMMENT',
            commentsDiff.in[index],
          );
        }
        for (let index = 0; index < commentsDiff.out.length; index++) {
          this.comments[commentsDiff.out[index]].destroy();
          delete this.comments[commentsDiff.out[index]];
        }
        delta.in.comments = commentsDiff.in.length > 0 ? commentsDiff.in : [];
        delta.out.comments = commentsDiff.out.length > 0 ? commentsDiff.out : [];
        !this.version && this.emit('CHANGED_DELTA', delta);
      }
      if (tempCommentsDiff.in.length > 0 || tempCommentsDiff.out.length > 0 || !this.loaded) {
        this.loaded = true;
        delta.in.temp = tempCommentsDiff.in;
        delta.out.temp = tempCommentsDiff.out;
        !this.version && this.emit('CHANGED_DELTA', delta);
      }
      this.timer = null;
    }, 250);
  }

  orderResults() {
    let orderedResults: NodeModel[] = [];
    let node: NodeModel;

    for (let index = 0, length = this.results.length; index < length; index++) {
      node = this.results[index];

      const sIndex = this.structure?.childNodes?.indexOf(node.id);
      if (sIndex !== undefined) {
        orderedResults[sIndex] = node;
      }
    }

    this.results = orderedResults.filter((item) => item !== undefined);
  }

  commentLocation(commentId: string) {
    return this.commentLocations?.[commentId] || [];
  }

  tempCommentLocation(commentId: string) {
    return this.tempCommentLocations[commentId] || [];
  }

  tempComments() {
    return Object.keys(this.tempCommentLocations);
  }

  isTempComment(commentId: string) {
    return !!this.tempCommentLocations[commentId];
  }

  setVersionData(
    version: any,
    data?: {
      index: string[];
      locations: {};
    },
  ) {
    //
    let index: string[];
    let oldIndex = this.version?.index || this.commentIndex;
    if (version) {
      this.version = {
        version,
        index: data?.index,
        locations: data?.locations,
      };
      index = this.version.index;
    } else {
      this.version = null;
      index = this.commentIndex;
    }
    const commentsDiff = CommentList.diffInOut(oldIndex, index);
    let delta: IndexerDeltaType<{
      comments: any[];
      temp: any[];
    }> = {
      in: { comments: [], temp: [] },
      out: { comments: [], temp: [] },
      changedOrder: commentsDiff.changedOrder,
    };
    if (commentsDiff.in.length > 0 || commentsDiff.out.length > 0 || commentsDiff.changedOrder) {
      for (let index = 0; index < commentsDiff.in.length; index++) {
        this.comments[commentsDiff.in[index]] = this.models.get('COMMENT', commentsDiff.in[index]);
      }
      for (let index = 0; index < commentsDiff.out.length; index++) {
        this.comments[commentsDiff.out[index]].destroy();
        delete this.comments[commentsDiff.out[index]];
      }
      delta.in.comments = commentsDiff.in.length > 0 ? commentsDiff.in : [];
      delta.out.comments = commentsDiff.out.length > 0 ? commentsDiff.out : [];
      this.emit('CHANGED_DELTA', delta);
    }
  }
}
